<template>
  <div class="bg-white">
    <main>
      <div class="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
          <div>
            <div class="flex flex-row">
              <img class="h-11 w-auto" src="https://i.imgur.com/IwWQbQP.png" alt="Your Company" />
            </div>
            <div class="mt-20">
              <div class="mt-6 sm:max-w-xl">
                <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Descubre el Poder del Diseño Comercial</h1>
                <p class="mt-6 text-xl text-gray-500">No te pierdas el único y último curso del año “Curso Digital de Diseño, Ejecución y Normativas de Espacios Comerciales en Malls y demás</p>
              </div>

              <form  class="mt-12 sm:flex sm:w-full">
                <div class="min-w-0 flex-1">
                  <input v-model="data1" id="hero-email" type="phone" class="block w-full rounded-md border border-gray-300 px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-[#FF4819] focus:ring-[#FF4819]" placeholder="Escribe tu número de WhatsApp" />
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-3">
                  <button @click="sendData(data1)" class="block w-full rounded-md border border-transparent bg-[#FF4819] px-5 py-3 text-base font-medium text-white shadow hover:bg-[#FF4819] focus:outline-none focus:ring-2 focus:ring-[#FF4819] focus:ring-offset-2 sm:px-10">Notifícame</button>
                </div>
              </form>
              <div class="mt-6">
                <div class="inline-flex items-center divide-x divide-gray-300">
                  <div class="flex flex-shrink-0 pr-5">
                    <svg class="w-5 h-5" v-for="(i, index) in 5" :key="index" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.9064 4.71875L16.3751 5.375C16.7501 5.4375 17.0626 5.6875 17.1876 6.0625C17.3126 6.40625 17.2189 6.8125 16.9376 7.0625L13.6876 10.2812L14.4689 14.8438C14.5314 15.2188 14.3751 15.5938 14.0626 15.8125C13.7501 16.0625 13.3439 16.0625 13.0001 15.9062L9.00012 13.75L4.96887 15.9062C4.65637 16.0625 4.21887 16.0625 3.93762 15.8125C3.62512 15.5938 3.46887 15.2188 3.53137 14.8438L4.28137 10.2812L1.03137 7.0625C0.750124 6.8125 0.656374 6.40625 0.781374 6.0625C0.906374 5.6875 1.21887 5.4375 1.59387 5.375L6.09387 4.71875L8.09387 0.5625C8.25012 0.21875 8.59387 0 9.00012 0C9.37512 0 9.71887 0.21875 9.87512 0.5625L11.9064 4.71875Z" fill="#FBBF24"/>
                    </svg>

                  </div>
                  <div class="min-w-0 flex-1 py-1 pl-5 text-sm text-gray-500 sm:py-3"><span class="font-medium text-gray-900">Más de </span> 25+ proyectos <span class="font-medium text-orange-500">entregados</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div class="hidden sm:block">
              <div class="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
              <PatternSvg/>
            </div>
            <div class="relative -mr-40 pl-4 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:max-w-none lg:pl-12">
              <img class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none" src="@/assets/portada.webp" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="relative mt-20">
        <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
          <div class="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div class="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
              <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                <defs>
                  <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
              <!-- Testimonial card-->
              <div class="aspect-square relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
                <img class="absolute inset-0 h-full w-full object-contain" src="@/assets/sofia.webp" alt="" />
                <!-- <div class="absolute inset-0 bg-orange-500 mix-blend-multiply" />
                <div class="absolute inset-0 bg-gradient-to-t from-orange-600 via-orange-600 opacity-90" /> -->
              </div>
            </div>
          </div>

          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <!-- Content area -->
            <div class="pt-12 sm:pt-16 lg:pt-20">
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sofia Silva</h2>
              <div class="mt-6 space-y-6 text-gray-500">
                <p class="text-lg">Cuenta con una amplia experiencia en el rubro de diseño comercial, diseño de interiores, una especialización en diseño de muebles y más de 100 proyectos realizados exitosamente en todo el Perú.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Logo cloud section -->
      <div class="mt-32">
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
            <div>
              <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">NUESTROS MAYORES COLABORADORES</h2>
              <p class="mt-6 max-w-3xl text-lg leading-7 text-gray-500">A través del tiempo Sofía ha filtrado más de 100 proveedores para sus proyectos, aprende y conoce de cerca las mejores marcas con las que trabaja cada proyecto.</p>
            </div>
            <div class="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
              <div v-for="logo in logos" :key="logo.name" class="col-span-1 flex justify-center bg-gray-50 py-8 px-8">
                <img class="max-h-12" :src="logo.url" :alt="logo.name" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CTA section -->
      <div class="relative mt-24 sm:mt-32 sm:py-16">
        <div aria-hidden="true" class="hidden sm:block">
          <div class="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
          <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
          </svg>
        </div>
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="relative overflow-hidden rounded-2xl bg-orange-500 px-6 py-10 shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                <path class="text-orange-400 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                <path class="text-orange-600 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
              </svg>
            </div>
            <div class="relative">
              <div class="sm:text-center">
                <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">No te pierdas este lanzamiento</h2>
                <p class="mx-auto mt-6 max-w-2xl text-lg text-orange-100">¡Da un paso adelante en tu carrera de diseño comercial! Inscríbete ahora y descubre cómo llevar tus habilidades al siguiente nivel.</p>
              </div>
              <form action="#" class="mt-12 sm:mx-auto sm:flex sm:max-w-lg">
                <div class="min-w-0 flex-1">
                  <label for="cta-email" class="sr-only">WhatsApp</label>
                  <input v-model="data2" id="cta-email" type="phone" class="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-500" placeholder="Escribe tu número de WhatsApp" />
                </div>
                <div class="mt-4 sm:mt-0 sm:ml-3">
                  <button @click="sendData(data2)" class="block w-full rounded-md border border-transparent bg-gray-900 px-5 py-3 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-orange-500 sm:px-10">Notifícame</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- Footer section -->
    <footer class="mt-24 bg-gray-900 sm:mt-12">
      <div class="mx-auto max-w-md overflow-hidden py-12 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div v-for="item in footerNavigation.main" :key="item.name" class="px-5 py-2">
            <a :href="item.href" class="text-base text-gray-400 hover:text-gray-300">{{ item.name }}</a>
          </div>
        </nav>
        <p class="mt-8 text-center text-base text-gray-400">Made with 💖 by Heroes Digitales 🦸🏽</p>
      </div>
    </footer>
  </div>
</template>

<script>
import PatternSvg from '@/components/patternSvg.vue';

import axios from 'axios';

export default {
  components: {
    PatternSvg,
  },
  data() {
    return {
      data1: '',
      data2: '',
      logos: [
        { name: '', url: 'https://i.imgur.com/hAoaQhO.png' },
        { name: '', url: 'https://i.imgur.com/2pnV5My.png' },
        { name: '', url: 'https://i.imgur.com/WjXP9Lt.png' },
        { name: '', url: 'https://i.imgur.com/9QaopTi.png' },
      ],
      footerNavigation: {
        main: [
        ]
      }
    }
  },

  methods: {
    async sendData(data) {
      console.log(data)
      const url = `https://hook.us1.make.com/x3c0495ztn4xxansasj41f27e4ednl8l?data=${data}`
      await axios.post(url)
      window.location.href = "https://chat.whatsapp.com/BUrMikVNhugKpx2wAGiACk";
    }
  }

}

</script>