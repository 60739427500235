<template>
	<svg
		class="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
		width="600"
		height="500"
		viewBox="0 0 600 500"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M104.85 304.98V314.97L138.57 324.25V325.75L104.85 334.94V344.93L144.82 328.7V321.21L104.85 304.98Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M227.79 324.95L244.47 319.31L248.78 316.82L243.81 308.2L239.49 310.69L226.26 322.31L229.72 305.04V300.06H219.76V305.04L223.22 322.31L209.98 310.69L205.67 308.2L200.69 316.82L205 319.31L221.69 324.95L205 330.6L200.69 333.09L205.67 341.71L209.98 339.22L223.22 327.59L219.76 344.87V349.84H229.72V344.87L226.26 327.59L239.49 339.22L243.81 341.71L248.78 333.09L244.47 330.6L227.79 324.95Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M49.8601 319.97V329.93H44.8801L27.4501 327.44L29.9401 344.86V349.84H19.9901V344.86L22.4801 327.44L5.06008 329.93H0.0800781V319.97H5.06008L22.4801 322.46L19.9901 305.04V300.07H29.9401V305.04L27.4501 322.46L44.8801 319.97H49.8601Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M348.68 337.07C348.68 343.72 343.29 349.11 336.64 349.11H324.6V325.03H336.64C343.29 325.03 348.68 330.42 348.68 337.07Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M300.53 312.99C300.53 306.34 305.92 300.95 312.57 300.95H324.61V325.03H312.57C305.92 325.03 300.53 319.64 300.53 312.99Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M338.9 317.52C343.473 317.52 347.18 313.813 347.18 309.24C347.18 304.667 343.473 300.96 338.9 300.96C334.327 300.96 330.62 304.667 330.62 309.24C330.62 313.813 334.327 317.52 338.9 317.52Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M308.81 349.11C313.383 349.11 317.09 345.403 317.09 340.83C317.09 336.257 313.383 332.55 308.81 332.55C304.237 332.55 300.53 336.257 300.53 340.83C300.53 345.403 304.237 349.11 308.81 349.11Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M503.5 304.98V314.97L537.22 324.25V325.75L503.5 334.94V344.93L543.48 328.7V321.21L503.5 304.98Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M625.51 324.95L642.2 319.31L646.51 316.82L641.53 308.2L637.22 310.69L623.99 322.31L627.44 305.04V300.06H617.49V305.04L620.94 322.31L607.71 310.69L603.4 308.2L598.42 316.82L602.73 319.31L619.42 324.95L602.73 330.6L598.42 333.09L603.4 341.71L607.71 339.22L620.94 327.59L617.49 344.87V349.84H627.44V344.87L623.99 327.59L637.22 339.22L641.53 341.71L646.51 333.09L642.2 330.6L625.51 324.95Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M449.4 319.97V329.93H444.42L427 327.44L429.49 344.86V349.84H419.53V344.86L422.02 327.44L404.6 329.93H399.62V319.97H404.6L422.02 322.46L419.53 305.04V300.07H429.49V305.04L427 322.46L444.42 319.97H449.4Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M746.41 337.07C746.41 343.72 741.02 349.11 734.37 349.11H722.33V325.03H734.37C741.02 325.03 746.41 330.42 746.41 337.07Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M698.26 312.99C698.26 306.34 703.65 300.95 710.3 300.95H722.34V325.03H710.3C703.65 325.03 698.26 319.64 698.26 312.99Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M736.63 317.52C741.203 317.52 744.91 313.813 744.91 309.24C744.91 304.667 741.203 300.96 736.63 300.96C732.057 300.96 728.35 304.667 728.35 309.24C728.35 313.813 732.057 317.52 736.63 317.52Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M706.54 349.11C711.113 349.11 714.82 345.403 714.82 340.83C714.82 336.257 711.113 332.55 706.54 332.55C701.967 332.55 698.26 336.257 698.26 340.83C698.26 345.403 701.967 349.11 706.54 349.11Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M503.5 104.79V114.77L537.22 124.05V125.56L503.5 134.75V144.73L543.48 128.5V121.01L503.5 104.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M625.51 124.76L642.2 119.11L646.51 116.63L641.53 108L637.22 110.49L623.99 122.12L627.44 104.85V99.8701H617.49V104.85L620.94 122.12L607.71 110.49L603.4 108L598.42 116.63L602.73 119.11L619.42 124.76L602.73 130.4L598.42 132.89L603.4 141.52L607.71 139.03L620.94 127.4L617.49 144.67V149.65H627.44V144.67L623.99 127.4L637.22 139.03L641.53 141.52L646.51 132.89L642.2 130.4L625.51 124.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M449.4 119.78V129.74H444.42L427 127.25L429.49 144.67V149.65H419.53V144.67L422.02 127.25L404.6 129.74H399.62V119.78H404.6L422.02 122.27L419.53 104.84V99.8701H429.49V104.84L427 122.27L444.42 119.78H449.4Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M746.41 136.88C746.41 143.53 741.02 148.92 734.37 148.92H722.33V124.84H734.37C741.02 124.84 746.41 130.23 746.41 136.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M698.26 112.8C698.26 106.15 703.65 100.76 710.3 100.76H722.34V124.84H710.3C703.65 124.84 698.26 119.45 698.26 112.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M736.63 117.32C741.203 117.32 744.91 113.613 744.91 109.04C744.91 104.467 741.203 100.76 736.63 100.76C732.057 100.76 728.35 104.467 728.35 109.04C728.35 113.613 732.057 117.32 736.63 117.32Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M706.54 148.92C711.113 148.92 714.82 145.213 714.82 140.64C714.82 136.067 711.113 132.36 706.54 132.36C701.967 132.36 698.26 136.067 698.26 140.64C698.26 145.213 701.967 148.92 706.54 148.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M104.85 104.79V114.77L138.57 124.05V125.56L104.85 134.75V144.73L144.82 128.5V121.01L104.85 104.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M227.79 124.76L244.47 119.11L248.78 116.63L243.81 108L239.49 110.49L226.26 122.12L229.72 104.85V99.8701H219.76V104.85L223.22 122.12L209.98 110.49L205.67 108L200.69 116.63L205 119.11L221.69 124.76L205 130.4L200.69 132.89L205.67 141.52L209.98 139.03L223.22 127.4L219.76 144.67V149.65H229.72V144.67L226.26 127.4L239.49 139.03L243.81 141.52L248.78 132.89L244.47 130.4L227.79 124.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M49.8601 119.78V129.74H44.8801L27.4501 127.25L29.9401 144.67V149.65H19.9901V144.67L22.4801 127.25L5.06008 129.74H0.0800781V119.78H5.06008L22.4801 122.27L19.9901 104.84V99.8701H29.9401V104.84L27.4501 122.27L44.8801 119.78H49.8601Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M348.68 136.88C348.68 143.53 343.29 148.92 336.64 148.92H324.6V124.84H336.64C343.29 124.84 348.68 130.23 348.68 136.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M300.53 112.8C300.53 106.15 305.92 100.76 312.57 100.76H324.61V124.84H312.57C305.92 124.84 300.53 119.45 300.53 112.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M338.9 117.32C343.473 117.32 347.18 113.613 347.18 109.04C347.18 104.467 343.473 100.76 338.9 100.76C334.327 100.76 330.62 104.467 330.62 109.04C330.62 113.613 334.327 117.32 338.9 117.32Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M308.81 148.92C313.383 148.92 317.09 145.213 317.09 140.64C317.09 136.067 313.383 132.36 308.81 132.36C304.237 132.36 300.53 136.067 300.53 140.64C300.53 145.213 304.237 148.92 308.81 148.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M304.62 204.79V214.78L338.34 224.06V225.56L304.62 234.75V244.74L344.6 228.51V221.02L304.62 204.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M427.56 224.76L444.24 219.12L448.56 216.63L443.58 208.01L439.27 210.5L426.03 222.13L429.49 204.85V199.87H419.53V204.85L422.99 222.13L409.75 210.5L405.44 208.01L400.47 216.63L404.78 219.12L421.46 224.76L404.78 230.41L400.47 232.9L405.44 241.52L409.75 239.03L422.99 227.4L419.53 244.68V249.66H429.49V244.68L426.03 227.4L439.27 239.03L443.58 241.52L448.56 232.9L444.24 230.41L427.56 224.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M249.63 219.79V229.74H244.65L227.22 227.25L229.71 244.67V249.65H219.76V244.67L222.25 227.25L204.83 229.74H199.85V219.79H204.83L222.25 222.28L219.76 204.85V199.88H229.71V204.85L227.22 222.28L244.65 219.79H249.63Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M547.56 236.88C547.56 243.53 542.17 248.92 535.52 248.92H523.48V224.84H535.52C542.17 224.84 547.56 230.23 547.56 236.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M499.41 212.8C499.41 206.15 504.8 200.76 511.45 200.76H523.49V224.84H511.45C504.8 224.84 499.41 219.45 499.41 212.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M537.78 217.33C542.353 217.33 546.06 213.623 546.06 209.05C546.06 204.477 542.353 200.77 537.78 200.77C533.207 200.77 529.5 204.477 529.5 209.05C529.5 213.623 533.207 217.33 537.78 217.33Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M507.69 248.92C512.263 248.92 515.97 245.213 515.97 240.64C515.97 236.067 512.263 232.36 507.69 232.36C503.117 232.36 499.41 236.067 499.41 240.64C499.41 245.213 503.117 248.92 507.69 248.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M702.35 204.79V214.78L736.07 224.06V225.56L702.35 234.75V244.74L742.32 228.51V221.02L702.35 204.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M28.0099 224.76L44.6999 219.12L49.0099 216.63L44.0299 208.01L39.7199 210.5L26.4899 222.13L29.9499 204.85V199.87H19.9899V204.85L23.4399 222.13L10.2099 210.5L5.89992 208.01L0.919922 216.63L5.22992 219.12L21.9199 224.76L5.22992 230.41L0.919922 232.9L5.89992 241.52L10.2099 239.03L23.4399 227.4L19.9899 244.68V249.66H29.9499V244.68L26.4899 227.4L39.7199 239.03L44.0299 241.52L49.0099 232.9L44.6999 230.41L28.0099 224.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M647.36 219.79V229.74H642.38L624.95 227.25L627.44 244.67V249.65H617.49V244.67L619.98 227.25L602.55 229.74H597.58V219.79H602.55L619.98 222.28L617.49 204.85V199.88H627.44V204.85L624.95 222.28L642.38 219.79H647.36Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M148.91 236.88C148.91 243.53 143.52 248.92 136.87 248.92H124.83V224.84H136.87C143.52 224.84 148.91 230.23 148.91 236.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M100.76 212.8C100.76 206.15 106.15 200.76 112.8 200.76H124.84V224.84H112.8C106.15 224.84 100.76 219.45 100.76 212.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M139.13 217.33C143.703 217.33 147.41 213.623 147.41 209.05C147.41 204.477 143.703 200.77 139.13 200.77C134.557 200.77 130.85 204.477 130.85 209.05C130.85 213.623 134.557 217.33 139.13 217.33Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M109.04 248.92C113.613 248.92 117.32 245.213 117.32 240.64C117.32 236.067 113.613 232.36 109.04 232.36C104.467 232.36 100.76 236.067 100.76 240.64C100.76 245.213 104.467 248.92 109.04 248.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M304.62 4.91992V14.8999L338.34 24.1799V25.6899L304.62 34.8799V44.8599L344.6 28.6399V21.1499L304.62 4.91992Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M427.56 24.89L444.24 19.25L448.56 16.76L443.58 8.13L439.27 10.62L426.03 22.25L429.49 4.98V0H419.53V4.98L422.99 22.25L409.75 10.62L405.44 8.13L400.47 16.76L404.78 19.25L421.46 24.89L404.78 30.54L400.47 33.03L405.44 41.65L409.75 39.16L422.99 27.53L419.53 44.8V49.78H429.49V44.8L426.03 27.53L439.27 39.16L443.58 41.65L448.56 33.03L444.24 30.54L427.56 24.89Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M249.63 19.91V29.87H244.65L227.22 27.38L229.71 44.8V49.78H219.76V44.8L222.25 27.38L204.83 29.87H199.85V19.91H204.83L222.25 22.4L219.76 4.98V0H229.71V4.98L227.22 22.4L244.65 19.91H249.63Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M547.56 37.9004C547.56 44.5504 542.17 49.9403 535.52 49.9403H523.48V25.8604H535.52C542.17 25.8604 547.56 31.2504 547.56 37.9004Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M499.41 13.8203C499.41 7.17027 504.8 1.78027 511.45 1.78027H523.49V25.8603H511.45C504.8 25.8603 499.41 20.4703 499.41 13.8203Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M537.78 18.35C542.353 18.35 546.06 14.643 546.06 10.07C546.06 5.49712 542.353 1.79004 537.78 1.79004C533.207 1.79004 529.5 5.49712 529.5 10.07C529.5 14.643 533.207 18.35 537.78 18.35Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M507.69 49.9404C512.263 49.9404 515.97 46.2333 515.97 41.6604C515.97 37.0875 512.263 33.3804 507.69 33.3804C503.117 33.3804 499.41 37.0875 499.41 41.6604C499.41 46.2333 503.117 49.9404 507.69 49.9404Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M702.35 4.91992V14.8999L736.07 24.1799V25.6899L702.35 34.8799V44.8599L742.32 28.6399V21.1499L702.35 4.91992Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M28.0099 24.89L44.6999 19.25L49.0099 16.76L44.0299 8.13L39.7199 10.62L26.4899 22.25L29.9499 4.98V0H19.9899V4.98L23.4399 22.25L10.2099 10.62L5.89992 8.13L0.919922 16.76L5.22992 19.25L21.9199 24.89L5.22992 30.54L0.919922 33.03L5.89992 41.65L10.2099 39.16L23.4399 27.53L19.9899 44.8V49.78H29.9499V44.8L26.4899 27.53L39.7199 39.16L44.0299 41.65L49.0099 33.03L44.6999 30.54L28.0099 24.89Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M647.36 19.91V29.87H642.38L624.95 27.38L627.44 44.8V49.78H617.49V44.8L619.98 27.38L602.55 29.87H597.58V19.91H602.55L619.98 22.4L617.49 4.98V0H627.44V4.98L624.95 22.4L642.38 19.91H647.36Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M148.91 37.9004C148.91 44.5504 143.52 49.9403 136.87 49.9403H124.83V25.8604H136.87C143.52 25.8604 148.91 31.2504 148.91 37.9004Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M100.76 13.8203C100.76 7.17027 106.15 1.78027 112.8 1.78027H124.84V25.8603H112.8C106.15 25.8603 100.76 20.4703 100.76 13.8203Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M139.13 18.35C143.703 18.35 147.41 14.643 147.41 10.07C147.41 5.49712 143.703 1.79004 139.13 1.79004C134.557 1.79004 130.85 5.49712 130.85 10.07C130.85 14.643 134.557 18.35 139.13 18.35Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M109.04 49.9404C113.613 49.9404 117.32 46.2333 117.32 41.6604C117.32 37.0875 113.613 33.3804 109.04 33.3804C104.467 33.3804 100.76 37.0875 100.76 41.6604C100.76 46.2333 104.467 49.9404 109.04 49.9404Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M104.85 703.98V713.97L138.57 723.25V724.75L104.85 733.94V743.93L144.82 727.7V720.21L104.85 703.98Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M227.79 723.95L244.47 718.31L248.78 715.82L243.81 707.2L239.49 709.69L226.26 721.31L229.72 704.04V699.06H219.76V704.04L223.22 721.31L209.98 709.69L205.67 707.2L200.69 715.82L205 718.31L221.69 723.95L205 729.6L200.69 732.09L205.67 740.71L209.98 738.22L223.22 726.59L219.76 743.87V748.84H229.72V743.87L226.26 726.59L239.49 738.22L243.81 740.71L248.78 732.09L244.47 729.6L227.79 723.95Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M49.8601 718.97V728.93H44.8801L27.4501 726.44L29.9401 743.86V748.84H19.9901V743.86L22.4801 726.44L5.06008 728.93H0.0800781V718.97H5.06008L22.4801 721.46L19.9901 704.04V699.07H29.9401V704.04L27.4501 721.46L44.8801 718.97H49.8601Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M348.68 736.07C348.68 742.72 343.29 748.11 336.64 748.11H324.6V724.03H336.64C343.29 724.03 348.68 729.42 348.68 736.07Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M300.53 711.99C300.53 705.34 305.92 699.95 312.57 699.95H324.61V724.03H312.57C305.92 724.03 300.53 718.64 300.53 711.99Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M338.9 716.52C343.473 716.52 347.18 712.813 347.18 708.24C347.18 703.667 343.473 699.96 338.9 699.96C334.327 699.96 330.62 703.667 330.62 708.24C330.62 712.813 334.327 716.52 338.9 716.52Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M308.81 748.11C313.383 748.11 317.09 744.403 317.09 739.83C317.09 735.257 313.383 731.55 308.81 731.55C304.237 731.55 300.53 735.257 300.53 739.83C300.53 744.403 304.237 748.11 308.81 748.11Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M503.5 703.98V713.97L537.22 723.25V724.75L503.5 733.94V743.93L543.48 727.7V720.21L503.5 703.98Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M625.51 723.95L642.2 718.31L646.51 715.82L641.53 707.2L637.22 709.69L623.99 721.31L627.44 704.04V699.06H617.49V704.04L620.94 721.31L607.71 709.69L603.4 707.2L598.42 715.82L602.73 718.31L619.42 723.95L602.73 729.6L598.42 732.09L603.4 740.71L607.71 738.22L620.94 726.59L617.49 743.87V748.84H627.44V743.87L623.99 726.59L637.22 738.22L641.53 740.71L646.51 732.09L642.2 729.6L625.51 723.95Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M449.4 718.97V728.93H444.42L427 726.44L429.49 743.86V748.84H419.53V743.86L422.02 726.44L404.6 728.93H399.62V718.97H404.6L422.02 721.46L419.53 704.04V699.07H429.49V704.04L427 721.46L444.42 718.97H449.4Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M746.41 736.07C746.41 742.72 741.02 748.11 734.37 748.11H722.33V724.03H734.37C741.02 724.03 746.41 729.42 746.41 736.07Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M698.26 711.99C698.26 705.34 703.65 699.95 710.3 699.95H722.34V724.03H710.3C703.65 724.03 698.26 718.64 698.26 711.99Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M736.63 716.52C741.203 716.52 744.91 712.813 744.91 708.24C744.91 703.667 741.203 699.96 736.63 699.96C732.057 699.96 728.35 703.667 728.35 708.24C728.35 712.813 732.057 716.52 736.63 716.52Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M706.54 748.11C711.113 748.11 714.82 744.403 714.82 739.83C714.82 735.257 711.113 731.55 706.54 731.55C701.967 731.55 698.26 735.257 698.26 739.83C698.26 744.403 701.967 748.11 706.54 748.11Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M503.5 503.79V513.77L537.22 523.05V524.56L503.5 533.75V543.73L543.48 527.5V520.01L503.5 503.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M625.51 523.76L642.2 518.11L646.51 515.63L641.53 507L637.22 509.49L623.99 521.12L627.44 503.85V498.87H617.49V503.85L620.94 521.12L607.71 509.49L603.4 507L598.42 515.63L602.73 518.11L619.42 523.76L602.73 529.4L598.42 531.89L603.4 540.52L607.71 538.03L620.94 526.4L617.49 543.67V548.65H627.44V543.67L623.99 526.4L637.22 538.03L641.53 540.52L646.51 531.89L642.2 529.4L625.51 523.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M449.4 518.78V528.74H444.42L427 526.25L429.49 543.67V548.65H419.53V543.67L422.02 526.25L404.6 528.74H399.62V518.78H404.6L422.02 521.27L419.53 503.84V498.87H429.49V503.84L427 521.27L444.42 518.78H449.4Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M746.41 535.88C746.41 542.53 741.02 547.92 734.37 547.92H722.33V523.84H734.37C741.02 523.84 746.41 529.23 746.41 535.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M698.26 511.8C698.26 505.15 703.65 499.76 710.3 499.76H722.34V523.84H710.3C703.65 523.84 698.26 518.45 698.26 511.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M736.63 516.32C741.203 516.32 744.91 512.613 744.91 508.04C744.91 503.467 741.203 499.76 736.63 499.76C732.057 499.76 728.35 503.467 728.35 508.04C728.35 512.613 732.057 516.32 736.63 516.32Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M706.54 547.92C711.113 547.92 714.82 544.213 714.82 539.64C714.82 535.067 711.113 531.36 706.54 531.36C701.967 531.36 698.26 535.067 698.26 539.64C698.26 544.213 701.967 547.92 706.54 547.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M104.85 503.79V513.77L138.57 523.05V524.56L104.85 533.75V543.73L144.82 527.5V520.01L104.85 503.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M227.79 523.76L244.47 518.11L248.78 515.63L243.81 507L239.49 509.49L226.26 521.12L229.72 503.85V498.87H219.76V503.85L223.22 521.12L209.98 509.49L205.67 507L200.69 515.63L205 518.11L221.69 523.76L205 529.4L200.69 531.89L205.67 540.52L209.98 538.03L223.22 526.4L219.76 543.67V548.65H229.72V543.67L226.26 526.4L239.49 538.03L243.81 540.52L248.78 531.89L244.47 529.4L227.79 523.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M49.8601 518.78V528.74H44.8801L27.4501 526.25L29.9401 543.67V548.65H19.9901V543.67L22.4801 526.25L5.06008 528.74H0.0800781V518.78H5.06008L22.4801 521.27L19.9901 503.84V498.87H29.9401V503.84L27.4501 521.27L44.8801 518.78H49.8601Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M348.68 535.88C348.68 542.53 343.29 547.92 336.64 547.92H324.6V523.84H336.64C343.29 523.84 348.68 529.23 348.68 535.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M300.53 511.8C300.53 505.15 305.92 499.76 312.57 499.76H324.61V523.84H312.57C305.92 523.84 300.53 518.45 300.53 511.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M338.9 516.32C343.473 516.32 347.18 512.613 347.18 508.04C347.18 503.467 343.473 499.76 338.9 499.76C334.327 499.76 330.62 503.467 330.62 508.04C330.62 512.613 334.327 516.32 338.9 516.32Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M308.81 547.92C313.383 547.92 317.09 544.213 317.09 539.64C317.09 535.067 313.383 531.36 308.81 531.36C304.237 531.36 300.53 535.067 300.53 539.64C300.53 544.213 304.237 547.92 308.81 547.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M304.62 603.79V613.78L338.34 623.06V624.56L304.62 633.75V643.74L344.6 627.51V620.02L304.62 603.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M427.56 623.76L444.24 618.12L448.56 615.63L443.58 607.01L439.27 609.5L426.03 621.13L429.49 603.85V598.87H419.53V603.85L422.99 621.13L409.75 609.5L405.44 607.01L400.47 615.63L404.78 618.12L421.46 623.76L404.78 629.41L400.47 631.9L405.44 640.52L409.75 638.03L422.99 626.4L419.53 643.68V648.66H429.49V643.68L426.03 626.4L439.27 638.03L443.58 640.52L448.56 631.9L444.24 629.41L427.56 623.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M249.63 618.79V628.74H244.65L227.22 626.25L229.71 643.67V648.65H219.76V643.67L222.25 626.25L204.83 628.74H199.85V618.79H204.83L222.25 621.28L219.76 603.85V598.88H229.71V603.85L227.22 621.28L244.65 618.79H249.63Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M547.56 635.88C547.56 642.53 542.17 647.92 535.52 647.92H523.48V623.84H535.52C542.17 623.84 547.56 629.23 547.56 635.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M499.41 611.8C499.41 605.15 504.8 599.76 511.45 599.76H523.49V623.84H511.45C504.8 623.84 499.41 618.45 499.41 611.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M537.78 616.33C542.353 616.33 546.06 612.623 546.06 608.05C546.06 603.477 542.353 599.77 537.78 599.77C533.207 599.77 529.5 603.477 529.5 608.05C529.5 612.623 533.207 616.33 537.78 616.33Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M507.69 647.92C512.263 647.92 515.97 644.213 515.97 639.64C515.97 635.067 512.263 631.36 507.69 631.36C503.117 631.36 499.41 635.067 499.41 639.64C499.41 644.213 503.117 647.92 507.69 647.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M702.35 603.79V613.78L736.07 623.06V624.56L702.35 633.75V643.74L742.32 627.51V620.02L702.35 603.79Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M28.0099 623.76L44.6999 618.12L49.0099 615.63L44.0299 607.01L39.7199 609.5L26.4899 621.13L29.9499 603.85V598.87H19.9899V603.85L23.4399 621.13L10.2099 609.5L5.89992 607.01L0.919922 615.63L5.22992 618.12L21.9199 623.76L5.22992 629.41L0.919922 631.9L5.89992 640.52L10.2099 638.03L23.4399 626.4L19.9899 643.68V648.66H29.9499V643.68L26.4899 626.4L39.7199 638.03L44.0299 640.52L49.0099 631.9L44.6999 629.41L28.0099 623.76Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M647.36 618.79V628.74H642.38L624.95 626.25L627.44 643.67V648.65H617.49V643.67L619.98 626.25L602.55 628.74H597.58V618.79H602.55L619.98 621.28L617.49 603.85V598.88H627.44V603.85L624.95 621.28L642.38 618.79H647.36Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M148.91 635.88C148.91 642.53 143.52 647.92 136.87 647.92H124.83V623.84H136.87C143.52 623.84 148.91 629.23 148.91 635.88Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M100.76 611.8C100.76 605.15 106.15 599.76 112.8 599.76H124.84V623.84H112.8C106.15 623.84 100.76 618.45 100.76 611.8Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M139.13 616.33C143.703 616.33 147.41 612.623 147.41 608.05C147.41 603.477 143.703 599.77 139.13 599.77C134.557 599.77 130.85 603.477 130.85 608.05C130.85 612.623 134.557 616.33 139.13 616.33Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M109.04 647.92C113.613 647.92 117.32 644.213 117.32 639.64C117.32 635.067 113.613 631.36 109.04 631.36C104.467 631.36 100.76 635.067 100.76 639.64C100.76 644.213 104.467 647.92 109.04 647.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M304.62 403.92V413.9L338.34 423.18V424.69L304.62 433.88V443.86L344.6 427.64V420.15L304.62 403.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M427.56 423.89L444.24 418.25L448.56 415.76L443.58 407.13L439.27 409.62L426.03 421.25L429.49 403.98V399H419.53V403.98L422.99 421.25L409.75 409.62L405.44 407.13L400.47 415.76L404.78 418.25L421.46 423.89L404.78 429.54L400.47 432.03L405.44 440.65L409.75 438.16L422.99 426.53L419.53 443.8V448.78H429.49V443.8L426.03 426.53L439.27 438.16L443.58 440.65L448.56 432.03L444.24 429.54L427.56 423.89Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M249.63 418.91V428.87H244.65L227.22 426.38L229.71 443.8V448.78H219.76V443.8L222.25 426.38L204.83 428.87H199.85V418.91H204.83L222.25 421.4L219.76 403.98V399H229.71V403.98L227.22 421.4L244.65 418.91H249.63Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M547.56 436.9C547.56 443.55 542.17 448.94 535.52 448.94H523.48V424.86H535.52C542.17 424.86 547.56 430.25 547.56 436.9Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M499.41 412.82C499.41 406.17 504.8 400.78 511.45 400.78H523.49V424.86H511.45C504.8 424.86 499.41 419.47 499.41 412.82Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M537.78 417.35C542.353 417.35 546.06 413.643 546.06 409.07C546.06 404.497 542.353 400.79 537.78 400.79C533.207 400.79 529.5 404.497 529.5 409.07C529.5 413.643 533.207 417.35 537.78 417.35Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M507.69 448.94C512.263 448.94 515.97 445.233 515.97 440.66C515.97 436.087 512.263 432.38 507.69 432.38C503.117 432.38 499.41 436.087 499.41 440.66C499.41 445.233 503.117 448.94 507.69 448.94Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M702.35 403.92V413.9L736.07 423.18V424.69L702.35 433.88V443.86L742.32 427.64V420.15L702.35 403.92Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M28.0099 423.89L44.6999 418.25L49.0099 415.76L44.0299 407.13L39.7199 409.62L26.4899 421.25L29.9499 403.98V399H19.9899V403.98L23.4399 421.25L10.2099 409.62L5.89992 407.13L0.919922 415.76L5.22992 418.25L21.9199 423.89L5.22992 429.54L0.919922 432.03L5.89992 440.65L10.2099 438.16L23.4399 426.53L19.9899 443.8V448.78H29.9499V443.8L26.4899 426.53L39.7199 438.16L44.0299 440.65L49.0099 432.03L44.6999 429.54L28.0099 423.89Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M647.36 418.91V428.87H642.38L624.95 426.38L627.44 443.8V448.78H617.49V443.8L619.98 426.38L602.55 428.87H597.58V418.91H602.55L619.98 421.4L617.49 403.98V399H627.44V403.98L624.95 421.4L642.38 418.91H647.36Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M148.91 436.9C148.91 443.55 143.52 448.94 136.87 448.94H124.83V424.86H136.87C143.52 424.86 148.91 430.25 148.91 436.9Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M100.76 412.82C100.76 406.17 106.15 400.78 112.8 400.78H124.84V424.86H112.8C106.15 424.86 100.76 419.47 100.76 412.82Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M139.13 417.35C143.703 417.35 147.41 413.643 147.41 409.07C147.41 404.497 143.703 400.79 139.13 400.79C134.557 400.79 130.85 404.497 130.85 409.07C130.85 413.643 134.557 417.35 139.13 417.35Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
		<path
			d="M109.04 448.94C113.613 448.94 117.32 445.233 117.32 440.66C117.32 436.087 113.613 432.38 109.04 432.38C104.467 432.38 100.76 436.087 100.76 440.66C100.76 445.233 104.467 448.94 109.04 448.94Z"
			fill="#FF4819"
			fill-opacity="0.3"
		/>
	</svg>
</template>
